<template>
  <b-modal id="select-jurisdiction-modal"
           ref="select-jurisdiction-modal"
           hide-header
           size="lg"
           @ok="jurisdictionSelected(selectedJurisdiction)"
  >
    <div class="pt-2 pr-2">
      <fa-icon class="float-right" icon="times" size="lg" @click="closeModal" />
    </div>
    <h2 class="pb-2">
      {{ title }}
    </h2>
    <p>
      {{ prompt }}
    </p>
    <div class="pb-2">
      <multi-select
        v-if="loaded"
        v-model="selectedJurisdiction"
        track-by="id"
        label="state_province_region"
        placeholder="Select the jurisdiction you'd like to file in."
        :options="jurisdictions"
        style="display: inline-block;"
      />
    </div>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import MultiSelect from 'vue-multiselect'

export default {
  name: 'SelectJurisdictionModal',
  components: { MultiSelect },
  props: {
    title: {
      type: String,
      required: true,
    },
    prompt: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loaded: false,
      selectedJurisdiction: {},
    }
  },
  computed: {
    ...mapGetters('jurisdictions', ['jurisdictions']),
  },
  async mounted() {
    await this.loadJurisdictions()
    this.loaded = true
  },
  methods: {
    ...mapActions('jurisdictions', { loadJurisdictions: 'load' }),
    jurisdictionSelected(jurisdiction) {
      this.$emit('jurisdiction-selected', jurisdiction)
      this.closeModal()
    },
    closeModal() {
      this.$refs['select-jurisdiction-modal'].hide()
    },
    show() {
      this.$refs['select-jurisdiction-modal'].show()
    },
  },
}
</script>
