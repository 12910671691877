var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      ref: "select-jurisdiction-modal",
      attrs: { id: "select-jurisdiction-modal", "hide-header": "", size: "lg" },
      on: {
        ok: function ($event) {
          return _vm.jurisdictionSelected(_vm.selectedJurisdiction)
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "pt-2 pr-2" },
        [
          _c("fa-icon", {
            staticClass: "float-right",
            attrs: { icon: "times", size: "lg" },
            on: { click: _vm.closeModal },
          }),
        ],
        1
      ),
      _c("h2", { staticClass: "pb-2" }, [
        _vm._v("\n    " + _vm._s(_vm.title) + "\n  "),
      ]),
      _c("p", [_vm._v("\n    " + _vm._s(_vm.prompt) + "\n  ")]),
      _c(
        "div",
        { staticClass: "pb-2" },
        [
          _vm.loaded
            ? _c("multi-select", {
                staticStyle: { display: "inline-block" },
                attrs: {
                  "track-by": "id",
                  label: "state_province_region",
                  placeholder: "Select the jurisdiction you'd like to file in.",
                  options: _vm.jurisdictions,
                },
                model: {
                  value: _vm.selectedJurisdiction,
                  callback: function ($$v) {
                    _vm.selectedJurisdiction = $$v
                  },
                  expression: "selectedJurisdiction",
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }