var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "stageline-decision" } },
    [
      _c(
        "div",
        { attrs: { id: "decision-exit-stageline" } },
        [
          _c(
            "router-link",
            { attrs: { to: { name: "root" } } },
            [
              _c("fa-icon", {
                staticClass: "fa-xs",
                attrs: { icon: "chevron-left" },
              }),
              _vm._v("\n      Exit Stageline\n    "),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-container",
        [
          _c("stageline-graphic", { attrs: { dummy: true } }),
          _c("div", { staticStyle: { "text-align": "center" } }, [
            _c("h1", [
              _vm._v("\n        What Would You Like To Do Next?\n      "),
            ]),
            _vm.company
              ? _c("h5", { attrs: { id: "stageline-decision-company-name" } }, [
                  _vm._v("\n        " + _vm._s(_vm.company.name) + "\n      "),
                ])
              : _vm._e(),
          ]),
          _c("div", [
            _c(
              "div",
              { attrs: { id: "decision-cards" } },
              _vm._l(_vm.decisions, function (decision) {
                return _c(
                  "div",
                  {
                    key: decision.title,
                    staticClass: "decision-card-container",
                  },
                  [
                    _c("decision-card", {
                      attrs: {
                        title: decision.title,
                        description: decision.description,
                        "button-text": decision.buttonText,
                      },
                      on: {
                        chosen: function ($event) {
                          return _vm.selectStageline(decision.stagelineType)
                        },
                      },
                    }),
                  ],
                  1
                )
              }),
              0
            ),
          ]),
        ],
        1
      ),
      _c("select-jurisdiction-modal", {
        ref: "select-jurisdiction-modal",
        attrs: {
          title: "Where would you like to expand?",
          prompt: "Select the jurisdiction where you plan to do business.",
        },
        on: {
          "jurisdiction-selected": _vm.handleSelectJurisdictionModalSubmit,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }