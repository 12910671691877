<template>
  <div id="stageline-page-v1-component">
    <stageline-decision v-if="!stagelineChosen" />
    <router-view v-else />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import StagelineDecision from '@/components/StagelineV1/StagelineDecision'

// Responsibilities and Notes:
// 1. Show Stageline and Stepline graphics
// 2. Load main stageline - Choose it (formation_stageline until completed, then decision step
// 4. Manage jurisdiction selection

export default {
  name: 'StagelinePageV1',
  components: {
    StagelineDecision,
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters('stageline', [
      'filteredStages',
      'company',
      'jurisdiction',
      'currentStage',
      'filteredStages',
      'stagelineLoaded',
    ]),
    ...mapGetters('companies', [
      'domesticRegistration',
      'checkoutDomesticJurisdiction',
    ]),
    stagelineChosen() {
      return !!this.$route.params.stagelineType
    },
  },
  watch:{
    $route (to, from){
      if(to.params.stagelineType && (to.params.stagelineType !== from.params.stagelineType)) {
        this.loadStagelineData()
      }
    },
  },
  async mounted() {
    if (this.$route.params.companyId) {
      await this.setCurrentCompany({ id: this.$route.params.companyId })
    } else {
      await this.$router.push({ name: 'root' })
      return
    }

    const jur = this.domesticRegistration?.jurisdiction || this.checkoutDomesticJurisdiction
    if (!jur) {
      await this.$router.push({ name: 'root' })
      return
    }

    this.setJurisdiction(jur)

    await this.setupContext({ companyId: this.company.id })

    // Needed for product upsells that include RA. Could optimize to load at a jurisdiction level
    await this.loadRAProducts()

    if (this.stagelineChosen) {
      await this.loadStagelineData()
    }
  },
  methods: {
    ...mapActions('stageline', [
      'setStagelineType',
      'loadStages',
      'setJurisdiction',
      'setCurrentStage',
      'setCurrentStep',
    ]),
    ...mapActions('checkout', ['setupContext', 'loadRAProducts']),
    ...mapActions('companies', ['setCurrentCompany']),
    async decideCurrentStage() {
      let index = this.filteredStages.findIndex(s => s.company_stage.completed_at === null)
      index = index === -1 ? this.filteredStages.length - 1 : index
       await this.setCurrentStage(index)
    },
    async loadStagelineData() {
      this.setStagelineType(this.$route.params.stagelineType)
      await this.loadStages()
      await this.decideCurrentStage()
    },

  },
}
</script>
<style lang="scss" scoped>
  #stageline-page-v1-component > div {
    display: flex;
    flex-direction: row;
  }

  @media only screen and (max-width: 1024px) {
    #stageline-page-v1-component {
      width: 100% !important;
    }
  }
</style>
