var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "decision-card shadow", on: { click: _vm.choose } },
    [
      _c("div", { staticClass: "side" }),
      _c("div", { staticClass: "main" }, [
        _c("div", { staticClass: "body" }, [
          _c("h3", [_vm._v(_vm._s(_vm.title))]),
          _c("p", [_vm._v(_vm._s(_vm.description))]),
        ]),
        _c(
          "a",
          { staticClass: "choice" },
          [
            _vm._v("\n      " + _vm._s(_vm.buttonText) + "\n      "),
            _c("fa-icon", { attrs: { icon: "chevron-right" } }),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }