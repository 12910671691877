<template>
  <div id="stageline-decision">
    <div id="decision-exit-stageline">
      <router-link :to="{ name: 'root' }">
        <fa-icon icon="chevron-left" class="fa-xs" />
        Exit Stageline
      </router-link>
    </div>
    <b-container>
      <stageline-graphic :dummy="true" />
      <div style="text-align: center;">
        <h1>
          What Would You Like To Do Next?
        </h1>
        <h5 v-if="company" id="stageline-decision-company-name">
          {{ company.name }}
        </h5>
      </div>
      <div>
        <div id="decision-cards">
          <div
            v-for="decision in decisions"
            :key="decision.title"
            class="decision-card-container"
          >
            <decision-card
              :title="decision.title"
              :description="decision.description"
              :button-text="decision.buttonText"
              @chosen="selectStageline(decision.stagelineType)"
            />
          </div>
        </div>
      </div>
    </b-container>
    <select-jurisdiction-modal
      ref="select-jurisdiction-modal"
      title="Where would you like to expand?"
      prompt="Select the jurisdiction where you plan to do business."
      @jurisdiction-selected="handleSelectJurisdictionModalSubmit"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import DecisionCard from '@/components/StagelineV1/cards/DecisionCard'
import StagelineGraphic from '@/components/StagelineV1/StagelineGraphic'
import SelectJurisdictionModal from '@/components/StagelineV1/modals/SelectJurisdictionModal'

export default {
  name: 'StagelineDecision',
  components: {
    DecisionCard,
    StagelineGraphic,
    SelectJurisdictionModal,
  },
  data() {
    return {
      decisions: [],
      chosenStageline: '',
    }
  },
  computed: {
    ...mapGetters('stageline', [
      'company',
      'stagelineType',
      'stagelineStatus',
    ]),
  },
  async mounted() {
    this.getDecisions()
  },
  methods: {
    ...mapActions('companies', [
      'updateCompanyConfig',
    ]),
    getDecisions() {
      if (this.company.entity_type === 'Individual' || this.company.entity_type === 'Sole Proprietorship') {
        this.decisions.push({
            title: 'Maintain',
            description: 'Need to file an annual or biennial report? Change registered agents? ' +
              'We\'re here to provide assistance with any additional filing you might need to make.',
            buttonText: 'Maintain Your Business',
            stagelineType: 'maintain',
          })
      }
      else {
        this.decisions.push({
            title: 'Maintain',
            description: 'Need to file an annual or biennial report? Change registered agents? ' +
              'We\'re here to provide assistance with any additional filing you might need to make.',
            buttonText: 'Maintain Your Business',
            stagelineType: 'maintain',
          },
          {
            title: 'Expand',
            description: 'Ready to take your business across state lines? We can help you register ' +
              'an existing business anywhere in the United States.',
            buttonText: 'Expand Your Business',
            stagelineType: 'expand',
          },
          {
            title: 'Dissolve',
            description: 'Ready to close up shop? We\'re here to help make sure you wind down your' +
              ' business properly and tie up any loose ends.',
            buttonText: 'Dissolve Your Business',
            stagelineType: 'dissolve',
          })
      }
    },
    selectStageline(stagelineChoice) {
      this.chosenStageline = stagelineChoice
      if (this.chosenStageline === 'expand' && [undefined, '', 'complete'].includes(this.stagelineStatus('expand'))){
        this.showSelectJurisdictionModal()
      } else {
        this.progressToStageline(this.chosenStageline)
      }
    },
    showSelectJurisdictionModal() {
      this.$refs['select-jurisdiction-modal'].show()
    },
    progressToStageline(stagelineType) {
      this.$router.push({ name: 'stageline', params: { companyId: this.company.id, stagelineType: stagelineType } })
    },
    async handleSelectJurisdictionModalSubmit(jur) {
      await this.setStagelineJurisdiction(this.chosenStageline, jur)

      this.progressToStageline(this.chosenStageline)
    },
    async setStagelineJurisdiction(stagelineType, jur) {
      const config = {}
      const stagelineTypeIdentifier = `${stagelineType}_stageline`
      config[stagelineTypeIdentifier] = {
        jurisdiction: jur.abbreviation,
      }
      const companyId = this.company.id

      await this.updateCompanyConfig({ companyId, config })
    },
  },
}
</script>

<style scoped lang="scss">
#stageline-decision {
  position: relative;
  margin: 60px auto auto auto !important;
  padding: 0 40px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  #stageline-graphic-component {
    // TODO implement later so current bubble in middle of screen.
    // position: relative;
    // left: 50px;
  }

  .header-container {
    justify-content: center;
    padding: 50px 0 20px 0;
  }

  #stageline-decision-company-name {
    //position: absolute;
    padding: 40px 0 0 0;
    color: $ct-ui-color-17;
    margin-top: 0;
    margin-bottom: 0;
  }

  #decision-exit-stageline {
    position: absolute;
    top: -30px;
    font-size: smaller;
    font-weight: 900;
    a {
      color: $ct-ui-font-color-default-grey;
    }
  }

  #decision-cards {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    padding: 10px;

    .decision-card-container {
      margin: 10px;
      flex: 1 1 200px;
    }
  }

  @media only screen and (max-width: 1055px) {
    .container {
      width: 100% !important;
      padding: 0 !important;
    }

    .decision-card-container {
      min-width: 500px;
    }
  }
}

@media only screen and (max-width: 660px) {
  #decision-cards {
    width: 100% !important;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding: 0;

    .decision-card-container {
      width: 100% !important;
      min-width: unset;
      flex: 0 0 auto !important;
    }
  }
}
</style>
