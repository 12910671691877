<template>
  <div class="decision-card shadow" @click="choose">
    <div class="side" />
    <div class="main">
      <div class="body">
        <h3>{{ title }}</h3>
        <p>{{ description }}</p>
      </div>
      <a class="choice">
        {{ buttonText }}
        <fa-icon icon="chevron-right" />
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "DecisionCard",
  props: {
    title: {
      type: String,
      default: 'Product',
    },
    description: {
      type: String,
      default: 'description',
    },
    buttonText: {
      type: String,
      default: 'Hire Us',
    },
  },
  data() {
    return {
    }
  },
  computed: {
  },
  methods: {
    choose() {
      this.$emit('chosen')
    },
  },
}
</script>

<style lang="scss" scoped>
  .decision-card {
    @include ct-ui-selectable;

    border-radius: 15px;
    min-height: 180px;
    height: 100%;

    .side {
      position: absolute;
      border-radius: 15px 0 0 15px;
      width: 10px;
      background-color: $ct-ui-background-2;
      height: 100%;
    }

    .main {
      position: relative;
      padding: 0 2.25em;
      height: 100%;
      .body {
        position: relative;
        h3 {
          padding-top: 1.875em;
        }
      }
      .choice {
        position: absolute;
        bottom: 20px;
        color: $ct-ui-color-3;
        cursor: pointer;
      }
    }

  }

  @media only screen and (max-width: 1024px) {
    .decision-card {
      width: 100% !important;
      padding-bottom: 1.0em;

      .main {

        .choice {
          bottom: 0;
        }
      }
    }
  }
</style>
